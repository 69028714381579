import React, {useState, useEffect, FC} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import styles from "../../styles/JackpotLive.module.scss";
import clsx from 'clsx'
import {Navigation} from "swiper";
import {Pagination} from "swiper";
import CMS from "utils/cms";
import PaginationArrow from "ui/paginationArrow";
import Skeleton from '@mui/material/Skeleton';
import Head from 'next/head';
import Gradients from "./gradients";
import {useSelector} from 'react-redux'
import JackpotCardNew from "themes/default/cms-views/JackpotsLive/jackpot_card_new";
import {lazy_loading_offset} from "@/AbsoluteComponents/utils/constants";
import LazyComponentWrap from "components/lazy_component_wrap";
import JackpotCardDynamic from "./jackpot_card_dynamic"

interface Props {
    json: any
    index?:any
}


const LiveJackpots:FC<Props> = (props) => {
    
    // const {index} = props
    
    // if (index >= lazy_loading_offset) {
    //     return (
    //         <LazyComponentWrap>
    //             <Core { ... props}/>
    //         </LazyComponentWrap>
    //     )
    // } else {
        return <Core { ... props}/>
    // }
}

export default LiveJackpots;

const Core: React.FC<Props> = ({json}) => {
    
    const data = CMS.jsonJackpotsData(json)
    const [jackpotsData, setJackpotsData] = useState<Array<any>>([])
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [swiper, setSwiper] = useState<any>();
    const [jackpots, setJackpots] = useState<Array<any>>([])
    const playJackpot = useSelector((state: any) => state.common.playJackpot)
    const [imagesLoaded, setImagesLoaded] = useState<boolean>(false)
    
    let skeletonLength = 10
    let skeletonArray: Array<any> = []
    for (let i = 0; i < skeletonLength; i++) {
        skeletonArray.push(i)
    }

    const fetchCredentials = async () => {
        const endpoint = process.env.NEXT_PUBLIC_CUSTOM_JACKPOTS_ENDPOINT || '/api/dynamic/jackpots.json'
        //

        try {
            const response = await fetch(endpoint, {
                method: 'GET',
            });

            if (response.ok) {
                const data = await response.json();
                
                setJackpots(data);
            } else {
                console.error('Failed to fetch jackpots data:', response.status);
            }
        } catch (error) {
            console.error('Error fetching jackpots data:', error);
        }
    }

    const getJackpots = () => {
        let jackpots: Array<any> = []
        let initialJp = data.jackpotsData

        initialJp.map((x: any, i: number) => {
            if (typeof x?.visible === "object" && x.visible.value === true) {
                let jp: any = {
                    name: x.name.value.text,
                    order: Number(x.order.value.value),
                    api_id: x.url.value.text,
                    visible: x.visible.value
                }

                jackpots.push(jp)
            }
        })

        setJackpotsData(jackpots.sort((a, b) => a.order - b.order))
    }
    
    const imageLoaded = (src:string) => {
        return new Promise((resolve, reject) => {
            const img = document.createElement('img');
            img.src = src;
            
            img.onload = () => {
                resolve(true);
            }
            
            img.onerror = () => {
                resolve(false);
            }
        })
    }
    
    const loadImages = async () => {
        
        let allImages = [
            "skyBg",
            "hotLuckBg",
            "egiptQuestBg",
            "jackpotCardsBg",
            "cloverBg",
            "bellLinkBg",
            "highCashBg",
            "regalJpBg",
        ]
        
        let arr = []
        for (let i = 0; i <= allImages.length - 1; i++) {
            await imageLoaded(`/spins/images/JackpotsLive/${allImages[i]}.webp`)
            arr.push(allImages[i])
            
            if(arr.length === allImages.length) {
                setImagesLoaded(true)
                
            }
            
        }
    }


    useEffect(() => {
        getJackpots()
        fetchCredentials()
        loadImages()
    }, [])

    useEffect(() => {
        if (jackpotsData.length) {
            // setTimeout(() => {
            //     setIsLoaded(true)
            // }, 2000)
            setIsLoaded(true)
            
        }

    }, [jackpotsData, playJackpot])


    let skyJackpot = jackpots.find((x: any) => {
        return x.jackpot_name === 'Sky Jackpot'
    })

    let hotLuck = jackpots.find((x: any) => {
        return x.jackpot_name === 'Hot Luck'
    })

    let egyptQuest = jackpots.find((x: any) => {
        return x.jackpot_name === 'Egypt Quest'
    })

    let jackpotCards = jackpots.find((x: any) => {
        return x.jackpot_name === 'Jackpot Cards'
    })

    let cloverChance = jackpots.find((x: any) => {
        return x.jackpot_name === 'Clover Chance'
    })

    let bellLink = jackpots.find((x: any) => {
        return x.jackpot_name === 'Bell Link'
    })

    let highCash = jackpots.find((x: any) => {
        return x.jackpot_name === 'High Cash'
    })

    let regalJackpot = jackpots.find((x: any) => {
        return x.jackpot_name === 'Regal Jackpot'
    })


    let standartGradient: any = [Gradients.standartGradient(), Gradients.standartGradientId]
    let hotLuckGradient: any = [Gradients.hotLuckGradient(), Gradients.hotLuckGradientId]
    let skyJackpotGradient: any = [Gradients.skyGradient(), Gradients.skyGradientId]


    return jackpotsData.length ? (
        <div className={clsx(styles.sliderContainer, 'theme-slider', 'liveJPCarousel')}>

            <Head>
                <meta name="color-scheme" content="light"/>
            </Head>

            <h1 className={'gamesSlider__title'}>
                {data.title}
            </h1>


            <Swiper
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination, Navigation]}
                className={clsx(styles.slider, 'swiper-wrapper', 'swiper-init-padding')}
                spaceBetween={20}
                slidesPerView='auto'
                onSwiper={(sw: any) => {
                    setSwiper(sw)
                }}
            >

                <PaginationArrow onClick={() => swiper.slideNext()}/>
                <PaginationArrow onClick={() => swiper.slidePrev()} rightArrow/>

                {/*{!isLoaded && !playJackpot.levels?.length && skeletonArray?.map((i) => {*/}
                
                {/*    let firstSlide: boolean = skeletonArray.indexOf(i) === 0*/}
                
                {/*    return (*/}
                {/*        <SwiperSlide key={'key' + i} className={clsx(styles.slider__slide, firstSlide && styles.first)}>*/}
                {/*            <Skeleton*/}
                {/*                variant="rectangular"*/}
                {/*                className={'skeletonJpCard'}*/}
                {/*                animation="wave"*/}
                {/*            />*/}
                {/*        </SwiperSlide>*/}
                {/*    )*/}
                {/*})}*/}
                
                {jackpotsData.length ? jackpotsData?.map((jp: any, i:number) => {
                    let firstSlide: boolean = jackpotsData.indexOf(jp) === 0
                    switch(jp.name){
                        
                        case "Clover Chance API":
                            return (typeof cloverChance === "object" && cloverChance.hasOwnProperty('levels'))  ? (
                                <SwiperSlide key={'jackpot' + i + jp.name} className={clsx(styles.slider__slide, firstSlide && styles.first )} >
                                    <JackpotCardNew
                                        api_id={jp.api_id} jackpot={cloverChance}  />
                                </SwiperSlide>
                            ) : (
                                <SwiperSlide key={'key' + i} className={clsx(styles.slider__slide, firstSlide && styles.first)}>
                                    <Skeleton
                                        variant="rectangular"
                                        className={'skeletonJpCard'}
                                        animation="wave"
                                    />
                                </SwiperSlide>
                            )

                        case "Bell Link API":
                            return (typeof bellLink === "object" && bellLink.hasOwnProperty('levels'))  ? (
                                <SwiperSlide key={'jackpot' + i + jp.name} className={clsx( styles.slider__slide, firstSlide && styles.first )} >
                                    <JackpotCardNew
                                        api_id={jp.api_id}
                                        jackpot={bellLink}
                                    />
                                </SwiperSlide>
                            ) : (
                                <SwiperSlide key={'key' + i} className={clsx(styles.slider__slide, firstSlide && styles.first)}>
                                    <Skeleton
                                        variant="rectangular"
                                        className={'skeletonJpCard'}
                                        animation="wave"
                                    />
                                </SwiperSlide>
                            )

                        case "Hot Luck API":
                            return (typeof hotLuck === "object" && hotLuck.hasOwnProperty('levels'))  ? (
                                <SwiperSlide key={'jackpot' + i + jp.name} className={clsx( styles.slider__slide, firstSlide && styles.first )} >
                                    <JackpotCardNew  api_id={jp.api_id} jackpot={hotLuck}  />
                                </SwiperSlide>
                            ) :  (
                                <SwiperSlide key={'key' + i} className={clsx(styles.slider__slide, firstSlide && styles.first)}>
                                    <Skeleton
                                        variant="rectangular"
                                        className={'skeletonJpCard'}
                                        animation="wave"
                                    />
                                </SwiperSlide>
                            )

                        case "High Cash API":
                            return (typeof highCash === "object" && highCash.hasOwnProperty('levels')) ? (
                                <SwiperSlide key={'jackpot' + i + jp.name} className={clsx( styles.slider__slide, firstSlide && styles.first )} >
                                    <JackpotCardNew  api_id={jp.api_id} jackpot={highCash}  />
                                </SwiperSlide>
                            ) : (
                                <SwiperSlide key={'key' + i} className={clsx(styles.slider__slide, firstSlide && styles.first)}>
                                    <Skeleton
                                        variant="rectangular"
                                        className={'skeletonJpCard'}
                                        animation="wave"
                                    />
                                </SwiperSlide>
                            )

                        case "Jackpot Cards API":
                            return (typeof jackpotCards === "object" && jackpotCards.hasOwnProperty('levels')) ? (
                                <SwiperSlide key={'jackpot' + i + jp.name}  className={clsx( styles.slider__slide, firstSlide && styles.first )} >
                                    <JackpotCardNew api_id={jp.api_id} jackpot={jackpotCards}  />
                                </SwiperSlide>
                            ) : (
                                <SwiperSlide key={'key' + i} className={clsx(styles.slider__slide, firstSlide && styles.first)}>
                                    <Skeleton
                                        variant="rectangular"
                                        className={'skeletonJpCard'}
                                        animation="wave"
                                    />
                                </SwiperSlide>
                            )

                        case "Egypt Quest API":
                            return ( typeof egyptQuest === "object" && egyptQuest.hasOwnProperty('levels')) ? (
                                <SwiperSlide key={'jackpot' + i + jp.name}  className={clsx( styles.slider__slide, firstSlide && styles.first )} >
                                    <JackpotCardNew  api_id={jp.api_id} jackpot={egyptQuest}  />
                                </SwiperSlide>
                            ) : (
                                <SwiperSlide key={'key' + i} className={clsx(styles.slider__slide, firstSlide && styles.first)}>
                                    <Skeleton
                                        variant="rectangular"
                                        className={'skeletonJpCard'}
                                        animation="wave"
                                    />
                                </SwiperSlide>
                            )

                        case "Sky Jackpot API":
                            return skyJackpot  ? (
                                <SwiperSlide key={'jackpot' + i + jp.name}  className={clsx( styles.slider__slide, firstSlide && styles.first )} >
                                    <JackpotCardNew  api_id={jp.api_id} jackpot={skyJackpot}  />
                                </SwiperSlide>
                            ) : (
                                <SwiperSlide key={'key' + i} className={clsx(styles.slider__slide, firstSlide && styles.first)}>
                                    <Skeleton
                                        variant="rectangular"
                                        className={'skeletonJpCard'}
                                        animation="wave"
                                    />
                                </SwiperSlide>
                            )

                        case "Play Jackpot API":
                            return playJackpot.hasOwnProperty('levels') ? (
                                <SwiperSlide key={'jackpot' + i + jp.name}  className={clsx( styles.slider__slide, firstSlide && styles.first )} >
                                    <JackpotCardDynamic  api_id={jp.api_id} jackpot={playJackpot}  />
                                </SwiperSlide>
                            ) : (
                                <SwiperSlide key={'key' + i} className={clsx(styles.slider__slide, firstSlide && styles.first)}>
                                    <Skeleton
                                        variant="rectangular"
                                        className={'skeletonJpCard'}
                                        animation="wave"
                                    />
                                </SwiperSlide>
                            )

                        case "Regal Jackpot API":
                            return (typeof regalJackpot === "object" && regalJackpot.hasOwnProperty('levels')) ? (
                                <SwiperSlide key={'jackpot' + i + jp.name}  className={clsx( styles.slider__slide, firstSlide && styles.first )} >
                                    <JackpotCardNew  api_id={jp.api_id} jackpot={regalJackpot}  />
                                </SwiperSlide>
                            ) : (
                                <SwiperSlide key={'key' + i} className={clsx(styles.slider__slide, firstSlide && styles.first)}>
                                    <Skeleton
                                        variant="rectangular"
                                        className={'skeletonJpCard'}
                                        animation="wave"
                                    />
                                </SwiperSlide>
                            )

                        default: return null
                    }
                }) : skeletonArray?.map((i) => {
                    
                    let firstSlide: boolean = skeletonArray.indexOf(i) === 0
                    
                    return (
                        <SwiperSlide key={'key' + i} className={clsx(styles.slider__slide, firstSlide && styles.first)}>
                            <Skeleton
                                variant="rectangular"
                                className={'skeletonJpCard'}
                                animation="wave"
                            />
                        </SwiperSlide>
                    )
                })}

            </Swiper>
        </div>
    ) : null
}

