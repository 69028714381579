import {ClickAwayListener} from '@mui/base';
import st from '@/template/styles/balance.module.scss'
import React, {ReactNode, useState} from "react";
import clsx from "clsx";
import strings from "@/AbsoluteComponents/utils/strings";
import {useDispatch, useSelector} from "react-redux";
import useAuth from "@/AbsoluteComponents/hooks/auth";
import {openWheelModal} from "@/AbsoluteComponents/store/modals/actions";
import ArrowLeft from "@/template/svg/arrow-left";
import {useRouter} from "next/router";
import Offer from "@/AbsoluteComponents/interfaces/offer";
import SmarticoClass from "@/AbsoluteComponents/utils/smartico_class";
import SmarticoMiniGame from "@/AbsoluteComponents/interfaces/smartico_minigames";
import CMS from "@/AbsoluteComponents/utils/cms";
import InstantWinsIcon from "@/template/svg/instant_wins_icon";
import ClaimableOfferInterface from "@/AbsoluteComponents/interfaces/claimable_offer_interface";

interface Bonus {
    icon: ReactNode
    label:string
    blink: boolean
    qt:number
    onClick():void
    visible:boolean
    no_chevrone?:boolean
}

const BonusCenter = () => {
    
    const instant_win_data = CMS.getInstantWin()
    
    const [open, setOpen] = useState(false);
    
    const enableSpin: string = useSelector((state: any) => state.common.enableSpinWheel);
    const offer:Array<Offer> = useSelector((state:any) =>  state.account.offers);
    const dispatch = useDispatch()
    const router  = useRouter()
    const auth = useAuth()
    const giftbox = auth.getSmarticoMiniGames().find((x:SmarticoMiniGame) =>
        x.saw_game_type === SmarticoClass.saw_game_types.giftbox
    )
    const claimable_offer:Array<ClaimableOfferInterface> = useSelector((state:any) => state.account.claimableOffers) || []
    // console.log("claimable_offer", claimable_offer)
    const smartico_wheel = auth.getSmarticoMiniGames().find((x:SmarticoMiniGame) =>
        x.saw_game_type === SmarticoClass.saw_game_types.wheel
    )
    
    const is_wheel = Boolean(typeof smartico_wheel === "object" && Object.keys(smartico_wheel).length)
    
    const blink = giftbox || Boolean(offer.length) || enableSpin === "enable"
    let badge_qt = offer.length + claimable_offer.length
    const smartico_props = useSelector((state:any) => state.account.smarticoProps);
    
    if(typeof giftbox !== "undefined" && Boolean(Object.keys(giftbox).length)) {
        badge_qt += 1
    }
    if(enableSpin === "enable") {
        badge_qt += 1
    }
    
    const bonus_list:Array<Bonus> = [
        {
            icon: <img alt="img" loading={"lazy"} width={"24"} height={"24"} src="/default/icons/icon_offers.svg"/>,
            label: "bonusurile mele",
            blink: offer.length > 0,
            qt: offer.length + claimable_offer.length,
            onClick: () => {
                if(auth.isLoggedIn()){
                    router.push("/account/available-offers")
                }
            },
            visible: true
        },
        
        {
            icon: <InstantWinsIcon />,
            label: instant_win_data?.menu_label || "",
            blink: true,
            qt: 0,
            onClick: () => {
                if(auth.isLoggedIn()){
                    router.push(instant_win_data?.slug || "/")
                }
            },
            visible: !instant_win_data.canceled
        },
        
        {
            icon: <WheelLocalIcon />,
            label: "roata norocului",
            blink: enableSpin === "enable",
            qt: 0,
            onClick: () => {
                if(smartico_wheel?.id) {
                    SmarticoClass.openDashboardMiniGame(smartico_wheel.id)
                }
            },
            visible: is_wheel
        },
        
        {
            icon: smartico_props?.avatar_id ? <img alt="img" loading={"lazy"} width={"24"} height={"24"} src={smartico_props?.avatar_id}/> : <></>,
            label: strings["play_hub"],
            blink: true,
            qt: 0,
            onClick: () => {
                SmarticoClass.openDashboard()
            },
            visible: true,
            no_chevrone: true
        },

    ]
    
    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div className={clsx(st.bonusCenter, open && st.active)}>
                <button
                    onClick={() => {
                        setOpen(!open)
                    }}
                    aria-label={"bonus center launch"}
                    className={clsx(st.main_btn, blink && st.blink)}
                >
                    <img
                        src={"/default/icons/icon_gift.svg"}
                        alt={"bonus center launch"}
                        width={32}
                        height={32}
                        style={{display: 'inline-block'}}
                    />
                    
                    {badge_qt > 0 ? (
                        <div className={st.badge}>
                            {badge_qt}
                        </div>
                    ) : null}
                </button>
                
                <div className={st.bonus_container}>
                    
                    {bonus_list.map((item:Bonus, i:number) => {
                        
                        return item.visible && (
                            <button
                                key={"bonus box"+i}
                                onClick={() => {
                                    setOpen(false)
                                    item.onClick()
                                }}
                                style={{
                                    animationDelay: `${0.2 * i}s`
                                }}
                                className={clsx(st.bonus_container__items, st.spinsStyle, item.blink && st.bonusStyle)}
                            >
                                <div className={clsx(st.left, st.sides)}>
                                    <div className={st.icon_container}>
                                        {item.icon}
                                    </div>
                                    
                                    <p style={{color: '#EEB665'}} className={st.bonus_container__title}>
                                        {item.label}
                                    </p>
                                </div>
                                
                                {!item.no_chevrone && (
                                    <div className={clsx(st.right, st.sides)}>
                                        {item.qt > 0 ? item.qt : <ArrowLeft />}
                                    </div>
                                )}
                                
                            
                            </button>
                        )
                    })}
                    
                </div>

            
            </div>
        </ClickAwayListener>
    )
}

const WheelLocalIcon = () => {
    
    const enableSpin: string = useSelector((state: any) => state.common.enableSpinWheel);
    const auth = useAuth()
    
    return (
        <>
            {(enableSpin === 'enable' && auth.isLoggedIn()) && (
                <img width={33} height={33} src={`/default/images/Wheel/wheelIcon2.svg`}  alt={'wheel'}/>
            )}
            
            {(enableSpin === 'disable' && auth.isLoggedIn()) && (
                <img width={33} height={33} src={`/default/images/Wheel/wheelIcon1.svg`}  alt={'wheel'}/>
            )}
            
            {(!auth.isLoggedIn() && !auth.isUpdating()) && (
                <img width={33} height={33} src={`/default/images/Wheel/wheelIcon1.svg`}  alt={'wheel'}/>
            )}
        </>
    )
}

export default BonusCenter