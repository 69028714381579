const GobletIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6.22659 2.60352H17.8191V4.0166H18.9429C19.8728 4.0166 20.7791 4.80413 20.5875 5.89068C20.32 7.40729 19.4488 9.58681 17.2662 10.6686C17.1018 11.2145 16.8967 11.7501 16.6454 12.2532C15.9394 13.6668 14.8162 14.9077 13.1607 15.2895L13.4446 16.0795C13.9093 17.3723 14.7318 18.5065 15.8162 19.35L16.5302 19.9053H17.5C17.9142 19.9053 18.25 20.2411 18.25 20.6553C18.25 21.0695 17.9142 21.4053 17.5 21.4053H6.50003C6.08582 21.4053 5.75003 21.0695 5.75003 20.6553C5.75003 20.2411 6.08582 19.9053 6.50003 19.9053H7.46194L8.27081 19.2762C9.30113 18.4748 10.096 17.4101 10.5715 16.1946L10.9223 15.2979C9.24639 14.9262 8.11172 13.677 7.40042 12.2528C7.15327 11.7579 6.95071 11.2316 6.78767 10.695C4.56555 9.62253 3.68221 7.4197 3.41256 5.89068C3.22095 4.80413 4.12731 4.0166 5.05713 4.0166H6.22659V2.60352ZM6.22659 5.5166H5.05713C4.98399 5.5166 4.93273 5.54641 4.90784 5.57292C4.89617 5.58535 4.89178 5.59518 4.89009 5.60053C4.88885 5.60449 4.88671 5.6128 4.88977 5.63016C5.05808 6.58457 5.48406 7.71535 6.33641 8.55965C6.26081 7.96767 6.22659 7.3978 6.22659 6.87986V5.5166ZM17.8191 6.87986V5.5166H18.9429C19.0161 5.5166 19.0673 5.54641 19.0922 5.57292C19.1039 5.58535 19.1083 5.59518 19.11 5.60053C19.1112 5.60449 19.1134 5.6128 19.1103 5.63016C18.9455 6.56483 18.5335 7.66864 17.716 8.50688C17.7869 7.93387 17.8191 7.38246 17.8191 6.87986ZM9.83769 19.9053H14.1724C13.2188 18.9795 12.4854 17.8455 12.0331 16.5868L12.031 16.5811L11.9684 16.741C11.4983 17.9429 10.7695 19.0225 9.83769 19.9053ZM7.72659 4.10352V6.87986C7.72659 8.31007 8.02315 10.1424 8.74237 11.5826C9.45508 13.0096 10.5037 13.9263 12.0147 13.9099L12.0229 13.9098L12.031 13.9099C13.5423 13.9263 14.5908 13.0098 15.3034 11.583C16.0226 10.1431 16.3191 8.3107 16.3191 6.87986V4.10352H7.72659Z"
                  fill="#EBBF69"/>
        </svg>
    )
}

export default GobletIcon