import {FC, ReactNode} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styles from 'themes/default/styles/button.module.scss';
import clsx from 'clsx';

interface Props {
    type?:"button" | "submit" | "reset" | undefined,
    text:any,
    disabled?:boolean,
    loading?:boolean,
    outlined?:boolean,
    outlinedDark?:boolean,
    large?:boolean,
    className?:string,
    onClick?(e?:React.MouseEvent<HTMLButtonElement>):void,
    style?:Object,
    icon?:JSX.Element
    deposit?:boolean,
    countdown?:ReactNode
    secondaryOutlined?:boolean
}

const Button:FC<Props> = ({
                              outlinedDark,
                              type,
                              text, disabled,
                              loading,
                              style,
                              onClick,
                              outlined,
                              large,
                              className,
                              icon,
                              deposit,
                              countdown,
                              secondaryOutlined
                            }) => {
    return (
        <button
            className={clsx(
                styles.root,
                deposit && 'depositBtn',
                outlined ? styles.outlined : styles.primary,
                outlinedDark && styles.outlinedDark ,
                large && styles.large,
                className && className,
                disabled && styles.disabled
            )}

            type={type || 'button'}
            style={style}
            disabled={disabled || loading}
            onClick={onClick}>
            {loading && <CircularProgress style={{width: 14, height: 14, marginRight: 8}}/>}

            {icon ? icon : null}

            <span>{text}</span>
            
            {countdown && countdown}
        </button>
    )
}

export default Button

