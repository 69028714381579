import React, {useState, useEffect, useRef} from 'react'
import {
    getAuthToken,
    getExpiresIn,
    getLoggedInTime,
    getPlayerId,
    getRefeshToken,
    saveRedirectTarget, setAuthToken, setExpiresIn, setLoggedInTime, setRefreshToken
} from "utils/cookies";
import useAuth from "hooks/auth";
import {useDispatch, useSelector} from 'react-redux';
import { useRouter } from 'next/router';
import useGame from "hooks/game";
import {setEnableWheel} from "store/common/actions";
import {openLoginModal} from "@/AbsoluteComponents/store/modals/actions";
import {show_lucky_ticket} from "themes/default/config/constants";
import CMS from "@/AbsoluteComponents/utils/cms";
import {pending_claims, refreshToken} from "@/AbsoluteComponents/services/account";


interface Props {
    children:any
}

const DefaultGeneralComponentView:React.FC<Props> = ({  children }) => {

    // GENERAL DEPENDANCIES START
    const auth = useAuth()
    const dispatch = useDispatch();
    const router = useRouter()
    const gameHook = useGame()
    let is_quest = router.asPath.search("vip-quest") > -1
    const interval = useRef<any>(null)
    
    // GENERAL DEPENDANCIES END

    // WHEEL DEPENDANCIES START
    const [canSpin, setCanSpin] = useState<boolean>(false);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [spinnedToday, setSpinnedToday] = useState<boolean>(true);
    const [isDefault, setIsDefault] = useState<boolean | null>(null);
    const wheelStatus:any = useSelector((state:any) => state.account.spinStatus);
    const timer:any = useRef(null);
    // WHEEL DEPENDANCIES END
    
    
    const runRefreshToken = async () => {
        const loggedTimestamp = Number(getLoggedInTime());
        const expiresInInSeconds = Number(getExpiresIn());
        // const expiresInInSeconds = 4;
        if(expiresInInSeconds && loggedTimestamp) {
            const expiresInInMilliseconds = expiresInInSeconds * 1000;
            const futureTimestamp = loggedTimestamp + expiresInInMilliseconds;
            const difference = 15000;
            
            if(Number(getExpiresIn()) > 0 && Date.now() >= futureTimestamp - difference) {
                (window as any)._refreshToken()
            }
        }
    }


    useEffect(() => {
        
        // CANDY QUEST GLOBAL STATES START   CANDY QUEST GLOBAL STATES START   CANDY QUEST GLOBAL STATES START   
        auth.updateQuestStatus("GET")

        // CANDY QUEST GLOBAL STATES END   CANDY QUEST GLOBAL STATES END   CANDY QUEST GLOBAL STATES END   
        
        const test = async () => {
            await pending_claims()
        }
        
        
        
        if(auth.isLoggedIn()) {
            auth.updateDocs()
            test()
        }

    }, [auth.isLoggedIn(), auth.isUpdating()])
    
    useEffect(() => {
        if(auth.isLoggedIn()) {
            
            (window as any)._refreshToken = async () => {
                if(getRefeshToken().length) {
                    const req = await refreshToken()
                    
                    const data = req.response
                    if(typeof data === "object" && Object.keys(data).length && data.hasOwnProperty("accessToken")) {
                        setAuthToken(data["accessToken"])
                        setRefreshToken(data["refreshToken"])
                        setExpiresIn(data["expiresIn"])
                        setLoggedInTime(Date.now())
                        
                        const tokenUpdateEvent = new CustomEvent("token_update", {
                            detail: {
                                expiresIn: String(data["expiresIn"])
                            }
                        })
                        
                        window.dispatchEvent(tokenUpdateEvent)
                    }
                }
            }
            
            runRefreshToken()
            
            timer.current = setInterval(() => {
                runRefreshToken()
            }, 5000)
            
        } else {
            clearInterval(timer.current)
        }
    }, [auth.isLoggedIn()]);

    useEffect(() => {
        auth.updateProfile();

        if(router.pathname !== "/"+CMS.getCurrentQuest().url_id) {
            gameHook.updateQuestLaderboard()
        }

    }, [])

    // WHEEL USE EFFECTS START   WHEEL USE EFFECTS START   WHEEL USE EFFECTS START   WHEEL USE EFFECTS START
    useEffect(() => {
        
        if(!is_quest && auth.isLoggedIn()) {
            auth.updateQuestStatus("GET")
        }
        
        const getMethods = async() => {
            if(auth.isLoggedIn()) {

                setCanSpin(true);

                let attributes: any
                let wasTriggered: string

                if(wheelStatus.length) {
                    // console.log(wheelStatus)
                    attributes = wheelStatus

                    let triggered = attributes.find((attr: any) => {
                        return attr.attributeName === 'WOFTriggered'
                    })
                    let wof_prizes = attributes.find((attr: any) => {
                        return attr.attributeName === 'WOFPrizes'
                    })
                    
                    wasTriggered = triggered.value
                    //test functionality
                    // setSpinnedToday(false);
                    // setIsDefault(false)

                    //propper functionality
                    if(wasTriggered === 'NotTriggered') {
                        setSpinnedToday(false)
                        // setCanSpin(true)
                    } else if (wasTriggered === 'Triggered') {
                        setSpinnedToday(true)
                        setCanSpin(false)
                        
                    }

                    if (wof_prizes.value === 'Default') {
                        setIsDefault(true)
                        setCanSpin(false)

                    } else {
                        setIsDefault(false)
                        setCanSpin(true)
                    }
                }

                setDataLoaded(true)
            }
        }

        if(auth.isLoggedIn() && wheelStatus.length) {
            getMethods()
        }

        if(!auth.isLoggedIn()) {
            setCanSpin(false)
            dispatch(setEnableWheel('loading'))
        }
        
        if(router.asPath.includes("/joc") && router.asPath.includes("/real") && !getAuthToken()) {
            saveRedirectTarget(router.asPath)
            router.push("/")
            dispatch(openLoginModal(true));
        }
        
        
        // RAFFLE START    RAFFLE START    RAFFLE START    RAFFLE START    RAFFLE START
        if(show_lucky_ticket) {
            if(auth.isLoggedIn()) {
                
                gameHook.userRaffleStatusUpdate("GET")
                
                interval.current = setInterval(() => {
                    let playerId = getPlayerId()
                    if(playerId.length > 0) {
                        gameHook.updateRaffleStatus(playerId)
                        clearInterval(interval.current)
                    }
                }, 50)
            }
            
            if(!getAuthToken().length) {
                gameHook.updateRaffleStatus()
            }
        }
        
        
        // RAFFLE END    RAFFLE END    RAFFLE END    RAFFLE END    RAFFLE END
        
    }, [auth.isLoggedIn(), wheelStatus]);

    useEffect(() => {
        if(dataLoaded) {
            if( canSpin && !spinnedToday && auth.isLoggedIn() && !isDefault) {
                dispatch(setEnableWheel('enable'))
            } else if (!canSpin || spinnedToday || isDefault === null || isDefault === true) {
                dispatch(setEnableWheel('disable'))
            }
        }

    }, [canSpin, dataLoaded, spinnedToday, auth.isLoggedIn(), isDefault])
    // WHEEL USE EFFECTS END   WHEEL USE EFFECTS END   WHEEL USE EFFECTS END   WHEEL USE EFFECTS END


    return (
        <div className={'general_component'}>
            {children}
        </div>
    )
}

export default DefaultGeneralComponentView