import Modal from 'react-modal';
import React, {FC, useState} from 'react';
import clsx from 'clsx';
import st from "@/template/styles/InstantWin.module.scss";
import ModalLayout from "@/template/ui/modal_layout";
import Button from "@/template/small_ui/button";
import useAuth from "@/AbsoluteComponents/hooks/auth";
import {openDeposit} from "@/AbsoluteComponents/store/modals/actions";
import {useDispatch} from "react-redux";

interface  Props {
    prize_id: number | null
    set_prize_handler(x:number | null):void
    all_prizes: Array<any>
    spin_count: number
    game_id: number
    cms_data: any
}

const InstantWinModal: FC<Props> = (props:any) => {
    
    const {
        prize_id,
        set_prize_handler,
        all_prizes,
        spin_count,
        game_id,
        cms_data
    } = props
    
    const {
        no_win_modal_title,
        no_win_modal_description,
        modal_count_text
    } = cms_data
    
    const count_text = modal_count_text.split("{{spin_count}}")
    const [loading, set_loading] = useState<boolean>(false)
    const auth = useAuth()
    const dispatch = useDispatch();
    
    const local_prize = (Array.isArray(all_prizes) && all_prizes.length) ? all_prizes.find((x:any) => {
        return x.id === prize_id
    }) : {}

    return (
        <Modal
            ariaHideApp={false}
            isOpen={Boolean(prize_id)}
            className={clsx(st.modal, 'ReactModal__Deposit')}
            onRequestClose={() => {
                set_prize_handler(null)
            }}
        >
            
            <ModalLayout
                title={'Share'}
                onClose={() => {set_prize_handler(null)}}
            >
                
                {(typeof local_prize === "object" && Object.keys(local_prize).length) ? (
                    
                    <div className={st.inner}>
                        
                        {local_prize.name === "NECASTIGATOR" ? (
                            <div className={st.no_prize}>
                                <strong>
                                    {no_win_modal_title}
                                </strong>
                                
                                <span>
                                    {no_win_modal_description}
                                </span>
                                
                                <img src={local_prize.icon} alt="icon"/>
                                
                            </div>
                        ) : (
                            <div className={st.is_prize}>
                                <strong>
                                    {local_prize.aknowledge_message}
                                </strong>
                                
                                <img src={local_prize.icon} alt="icon" />

                            </div>
                        )}
                        
                        
                        {spin_count > 0 ? (
                            <>
                                <span>
                                    
                                    {count_text.length && (
                                        <>
                                            {count_text[0]}
                                            
                                            <span
                                                className={st.yellow}
                                            >
                                                {`${spin_count} ${spin_count === 1 ? "șansă" : "șanse"}`}
                                            </span>
                                            
                                            {count_text[1]}
                                        </>
                                    )}
                                    
                                    
                                </span>
                                
                                <Button
                                    className={st.run_btn}
                                    text={"Deschide cadoul"}
                                    loading={loading}
                                    onClick={() => {
                                        
                                        set_loading(true);
                                        
                                        (window._smartico).api.playMiniGame(Number(game_id)).then((result: any) => {
                                            
                                            set_prize_handler(result.prize_id);
                                            
                                            auth.updSmarticoMiniGames()
                                            
                                        }).finally(() => {
                                            set_loading(false);
                                            
                                        });
                                        
                                    }}
                                />
                            </>
                        ) : (
                            <>
                                
                                <Button
                                    className={st.run_btn}
                                    text={"Câștigă instant"}
                                    loading={loading}
                                    onClick={() => {
                                        dispatch(openDeposit(true))
                                        set_prize_handler(null)
                                    }}
                                />
                            </>
                        )}
                        
                    </div>
                    
                ) : <></>}
                
            </ModalLayout>
        
        </Modal>
    )
}

export default InstantWinModal