import {FC} from 'react';
import Image from "next/image";


interface Props {
    onClose?():void,
    children:JSX.Element
    title?:string
    no_close?: boolean
}

const ModalLayout:FC<Props> = ({onClose, children}) => {

    return (
        <>
            {typeof onClose === "function" && (
                <button
                    aria-label={"close"}
                    onClick={onClose}
                    className={'ReactModal__Close'}
                >
                    <Image src={'/default/icons/close.svg'} width={15} height={15} alt={'close'}/>
                </button>
            )}

            <div className="ReactModal__Inner">

                {children}

            </div>
        </>
    )

};

export default ModalLayout