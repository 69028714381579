import {FC} from 'react';
import clsx from 'clsx';


interface Props {
    color?: string;
    width: number;
    height: number;
}

const InfoIconModal: FC<Props>  = ({color, width, height }) => {
    return (
        <svg color={color}  width={width}  height={height} className={clsx('svg-icon', )} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24">
            <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-.001 5.75c.69 0 1.251.56 1.251 1.25s-.561 1.25-1.251 1.25-1.249-.56-1.249-1.25.559-1.25 1.249-1.25zm2.001 12.25h-4v-1c.484-.179 1-.201 1-.735v-4.467c0-.534-.516-.618-1-.797v-1h3v6.265c0 .535.517.558 1 .735v.999z"/></svg>
    )
}

export default InfoIconModal