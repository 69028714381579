import React from "react";
import clsx from "clsx";
import styles from "@/template/styles/JackpotLive.module.scss";
import Image from "next/image";
import {useSelector} from "react-redux";
import {useMediaQuery} from "react-responsive";
import {useRouter} from "next/router";
import JackpotCounterNew from "themes/default/cms-views/JackpotsLive/jackpot_counter_new";
import PlayLight from 'themes/default/svg/playLight';
import LazyImage from "@/AbsoluteComponents/ui/LazyImage";

interface Props {
    jackpot: any
    api_id: string
}

const JackpotCardNew:React.FC<Props> = ({jackpot, api_id}) => {
    const isMedia1 = useMediaQuery({
        query: '(max-width: 991px)'
    });
    const isMobile:boolean = useSelector((state:any) => state.common.isMobile);
    const dynamicClassName = styles[jackpot.bgImage];
    const parentClassName = styles['parent_'+jackpot.bgImage];
    const contentClassName = styles['content_'+jackpot.bgImage];
    const router = useRouter()
    
    const navigateToJPPage = () => {router.push('/'+api_id)}
    
    const topValsStyle = {maxHeight: "26px", width: `fit-content`}
    const midValsStyle = {maxHeight: "24px", width: `fit-content`}
    
    
    return (
        <div onClick={(isMobile || isMedia1) ? navigateToJPPage : () => {
        }} className={clsx(styles.JackpotCard, dynamicClassName, parentClassName, 'jackpot-card', jackpot.bgImage)}>
            
            {/*<Image*/}
            {/*    src={`/default/images/JackpotsLive/${jackpot.bgImage}.webp`}*/}
            {/*    alt={jackpot.jackpot_name}*/}
            {/*    width={590}*/}
            {/*    height={800}*/}
            {/*    className={styles.JackpotCard__bg_img}*/}
            
            {/*/>*/}
            
            <LazyImage
                src={`/default/images/JackpotsLive/${jackpot.bgImage}.webp`}
                alt={jackpot.jackpot_name}
                // width={590}
                // height={800}
                className={styles.JackpotCard__bg_img}
            
            />
            
            <div className={clsx(styles.JackpotCard__content, contentClassName)}>
                {jackpot.jackpot_name === 'Sky Jackpot' && (
                    <div className={styles.skyValue}>
                        
                        <JackpotCounterNew
                            id={"counter_top"+jackpot.bgImage}
                            textWidth={17}
                            textHeight={26}
                            style={topValsStyle}
                            className={styles.valueContainer}
                            jackpot={jackpot}
                            value={jackpot.levels[0].value && jackpot.levels[0].value}
                        />
                        
                        <span className={styles.ron}>
                            ron
                        </span>
                    </div>
                )}
                
                {jackpot.jackpot_name !== 'Sky Jackpot' && (
                    <>
                        {/*TOP START TOP START TOP START TOP START TOP START TOP START TOP START TOP START */}
                        
                        <div
                            className={
                                clsx(
                                    styles.topValues,
                                    styles.values,
                                    jackpot.jackpot_name === 'Clover Chance' ||
                                    jackpot.jackpot_name === 'Hot Luck' ||
                                    jackpot.jackpot_name === 'High Cash' ||
                                    jackpot.jackpot_name === 'Egypt Quest' ||
                                    jackpot.jackpot_name === 'Regal Jackpot' ?
                                        styles.rowFlex : styles.columnFlex
                                )
                            }
                        >
                            {
                                (
                                    jackpot.jackpot_name === 'Clover Chance' ||
                                    jackpot.jackpot_name === 'Hot Luck' ||
                                    jackpot.jackpot_name === 'High Cash' ||
                                    jackpot.jackpot_name === 'Egypt Quest' ||
                                    jackpot.jackpot_name === 'Regal Jackpot'
                                ) ? (
                                    <>
                                        <div className={styles.leftSide}>
                                        
                                        </div>
                                        
                                        <JackpotCounterNew
                                            id={"counter_top"+jackpot.bgImage}
                                            textWidth={17}
                                            textHeight={26}
                                            className={styles.valueContainer}
                                            jackpot={jackpot}
                                            style={topValsStyle}
                                            value={jackpot.levels[0].value && jackpot.levels[0].value}
                                        />
                                        
                                        <span className={clsx(styles.ron, styles.rightSide)}>
                                            ron
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        
                                        <JackpotCounterNew
                                            id={"counter_top"+jackpot.bgImage}
                                            textWidth={17}
                                            textHeight={26}
                                            className={styles.valueContainer}
                                            jackpot={jackpot}
                                            style={topValsStyle}
                                            value={jackpot.levels[0].value && jackpot.levels[0].value}
                                        />
                                        
                                        <span className={clsx(styles.ron, styles.rightSide)}>
                                            ron
                                        </span>
                                    
                                    </>
                                )
                            }
                        
                        </div>
                        
                        {/*TOP END TOP END TOP END TOP END TOP END TOP END TOP END TOP END */}
                        
                        {/*MID START MID START MID START MID START MID START MID START MID START MID START */}
                        <div className={
                            clsx(
                                styles.midValues,
                                styles.values,
                                jackpot.jackpot_name === 'Clover Chance' ||
                                jackpot.jackpot_name === 'Hot Luck' ||
                                jackpot.jackpot_name === 'High Cash' ||
                                jackpot.jackpot_name === 'Egypt Quest' ||
                                jackpot.jackpot_name === 'Regal Jackpot' ?
                                    styles.rowFlex : styles.columnFlex
                            )}
                        >
                            
                            <JackpotCounterNew
                                id={"counter_mid"+jackpot.bgImage}
                                textWidth={16}
                                textHeight={26}
                                className={styles.midValue}
                                jackpot={jackpot}
                                style={midValsStyle}
                                value={jackpot.levels[1].value && jackpot.levels[1].value}
                            />
                            
                            <span className={styles.ron}>
                                ron
                            </span>
                        
                        </div>
                        
                        <div
                            className={
                                clsx(
                                    styles.botValues,
                                    styles.values,
                                    jackpot.jackpot_name === 'Clover Chance' ||
                                    jackpot.jackpot_name === 'Hot Luck' ||
                                    jackpot.jackpot_name === 'High Cash' ||
                                    jackpot.jackpot_name === 'Egypt Quest' ||
                                    jackpot.jackpot_name === 'Regal Jackpot' ?
                                        styles.rowFlex : styles.botColumnFlex
                                )
                            }
                        >
                            
                            {jackpot.jackpot_name !== 'Hot Luck' ? (
                                <>
                                    <div className={clsx(styles.leftSide, styles.sides)}>

                                        <span className={clsx(styles.value)}>
                                        {/*{ minorValue && (*/}
                                            {/*    jackpot.jackpot_name === 'Jackpot Cards' ||*/}
                                            {/*    jackpot.jackpot_name === 'Egypt Quest' ||*/}
                                            {/*    jackpot.jackpot_name === 'Clover Chance' ||*/}
                                            {/*    jackpot.jackpot_name === 'Regal Jackpot'*/}
                                            
                                            {/*) ? (*/}
                                            <JackpotCounterNew
                                                id={"counter_bot_left"+jackpot.bgImage}
                                                textWidth={10}
                                                textHeight={15}
                                                className={styles.botValue}
                                                jackpot={jackpot}
                                                value={jackpot.levels[2].value && jackpot.levels[2].value}
                                                is_static={jackpot.bgImage === "bellLinkBg" || jackpot.bgImage === "highCashBg"}
                                                
                                                // couldBeDistort={true}
                                            />
                                            {/*// ) : Number(minorValue).toFixed(2)}*/}
                                        </span>
                                        
                                        <span className={styles.ron}>
                                            ron
                                        </span>
                                    
                                    </div>
                                    
                                    <div className={clsx(styles.rightSide, styles.sides)}>

                                        <span className={clsx(styles.value)}>
                                        {/*{ minorValue && (*/}
                                            {/*    jackpot.jackpot_name === 'Jackpot Cards' ||*/}
                                            {/*    jackpot.jackpot_name === 'Egypt Quest' ||*/}
                                            {/*    jackpot.jackpot_name === 'Clover Chance' ||*/}
                                            {/*    jackpot.jackpot_name === 'Regal Jackpot'*/}
                                            
                                            {/*) ? (*/}
                                            <JackpotCounterNew
                                                couldBeDistort={true}
                                                id={"counter_bot_right"+jackpot.bgImage}
                                                textWidth={10}
                                                textHeight={15}
                                                className={styles.botValue}
                                                jackpot={jackpot}
                                                value={jackpot.levels[3].value && jackpot.levels[3].value}
                                                is_static={jackpot.bgImage === "bellLinkBg" || jackpot.bgImage === "highCashBg"}
                                            
                                            />
                                            {/*    ) : Number(miniValue).toFixed(2)*/}
                                            {/*    */}
                                            {/*}*/}
                                            
                                        </span>
                                        
                                        <span className={styles.ron}>
                                            ron
                                        </span>
                                    
                                    </div>
                                </>
                            
                            ) : (
                                <>
                                    <div className={clsx(styles.center, styles.sides, styles.hotLuckBot)}>
                                        <JackpotCounterNew
                                            couldBeDistort={true}
                                            id={"counter_bot_center"+jackpot.bgImage}
                                            textWidth={10}
                                            textHeight={15}
                                            className={styles.botValue}
                                            jackpot={jackpot}
                                            value={jackpot.levels[2].value && jackpot.levels[2].value}
                                            
                                        />
                                        
                                        <span className={styles.ron}>
                                            ron
                                        </span>
                                    </div>
                                </>
                            )}
                        
                        </div>
                        
                        {/*BOT END BOT END BOT END BOT END BOT END BOT END */}
                    
                    </>
                )}
                
                <button onClick={navigateToJPPage} className={styles.JackpotCard__playBtn}>
                    <div className={styles.button_inner}>
                        <PlayLight/>
                    </div>
                </button>
            
            </div>
        
        </div>
    )
}

export default JackpotCardNew