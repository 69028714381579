import Modal from "react-modal";
import clsx from "clsx";
import st from "@/template/styles/InstantWin.module.scss";
import ModalLayout from "@/template/ui/modal_layout";
import React from "react";
import CMS from "@/AbsoluteComponents/utils/cms";
import Button from "@/template/small_ui/button";


interface Props {
    open:boolean
    open_handler(x:boolean):void
    data:any
}

const InstantWinInfo:React.FC<Props> = (props) => {
    
    const {
        open,
        open_handler,
        data
    } = props
    
    const {
        info_modal_title,
        info_modal_description,
        info_modal_image,
        slug
    } = data
    
    return (
        <Modal
            ariaHideApp={false}
            isOpen={open}
            className={clsx(st.modal, 'ReactModal__Deposit')}
            onRequestClose={() => {
                open_handler(false)
            }}
        >
            
            <ModalLayout
                title={'Share'}
                onClose={() => {open_handler(false)}}
            >
                
                <div className={st.inner}>
                    <strong>
                        {info_modal_title}
                    </strong>
                    
                    <img src={CMS.getPublicFilePath(info_modal_image[0]['name'], slug.replace("/", ""), info_modal_image[0]['path'])} alt="info_modal_image" />
                    
                    <span>
                        {info_modal_description}
                    </span>
                    
                    <Button
                        onClick={() => {open_handler(false)}}
                        text={"OK"}
                    />
                    
                </div>
            
            </ModalLayout>
        
        </Modal>
    )
}

export default InstantWinInfo